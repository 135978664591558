const spacerSizes = {
	layout1: "h-layout1",
	layout2: "h-layout2",
	layout3: "h-layout3",
	layout4: "h-layout3 md:h-layout4",
	layout5: "h-layout3 md:h-layout5",
	layout6: "h-layout3 md:h-layout6",
	layout7: "h-layout3 md:h-layout7",
};

interface Props {
	size: keyof typeof spacerSizes;
	className?: string;
}

export function Spacer({ size, className = "" }: Props) {
	return <div className={`${className} ${spacerSizes[size]}`} />;
}
